#dishes-screen {
	h2 {
		margin: 0;
		margin-bottom: calc(#{$module} * 0.5);
	}
	.user {
		color: map-get($theme-colors, 'secondary');
	}
	.empty-posts {
		margin-top: calc(#{$module} * 1);
		background-color: map-get($theme-colors, 'light-grey');
		padding: calc(#{$module} * 1) 0;
		text-align: center;
		color: map-get($theme-colors, 'text');
		border-radius: 5px;
	}

	.all-posts {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: calc(#{$module} * 2);
	}
}

.special-select {
	margin-bottom: 12px;
}

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 44px;
	height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(18px);
	-ms-transform: translateX(18px);
	transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.toggle {
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 6px 0;
}
