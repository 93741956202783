.quill-btn {
	display: flex;
	flex-direction: column;
	.text-editor {
		z-index: 0;
		margin-bottom: calc(#{$module} / 2);
	}
	.my-btn.quill-btn {
		align-self: flex-end;
	}
	margin-bottom: 24px;
}
