form {
	display: flex;
	flex-direction: column;
	width: 100%;
	.input-images {
		display: flex;
		justify-content: space-between;
	}
}

input,
select,
textarea {
	width: 100%;
	border: none;
	outline: none;
	@include font-line-height($font-xs);
	border: 1px solid map-get($theme-colors, 'light-grey');
	border-radius: 5px;
	padding: calc(#{$module} / 2) calc(#{$module} / 3);
	height: 35px;
	margin-right: 4px;
	margin-bottom: 12px;
	&::placeholder {
		@include font-line-height($font-xs);
		padding: calc(#{$module} / 2);
		padding-left: 0;
		color: map-get($theme-colors, 'light-grey');
	}
	&:focus {
		border: 1px solid map-get($theme-colors, 'ds-main');
	}
	&.file-input,
	&[type='file'] {
		width: 100%;
		background-color: white;
		outline: none;
		color: map-get($theme-colors, 'text');
		font-weight: $text-light;
		@include font-line-height(10px);
		margin-bottom: calc(#{$module} / 2);
		border-bottom: none;
		overflow: hidden;
		border: none;
		height: 44px;
		margin-right: 4px;
		&::-webkit-file-upload-button {
			visibility: hidden;
		}
		&:focus {
			border: none;
		}
		&::before {
			content: 'Elegir archivo...';
			background: white;
			color: map-get($theme-colors, 'dark-grey');
			border: 1px solid map-get($theme-colors, 'dark-grey');
			height: 25px;
			padding: calc(#{$module} / 6) calc(#{$module} / 4);
			outline: none;
			white-space: nowrap;
			cursor: pointer;
			font-size: 10px;
			width: 100%;
			border-radius: 5px;
		}
		&:hover {
			&::before {
				border-color: map-get($theme-colors, 'text');
				color: map-get($theme-colors, 'text');
			}
		}
		&:active {
			&::before {
				background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
			}
		}
	}
}
label {
	@include font-line-height($font-xs);
	margin-right: calc(#{$module} / 3);
	font-weight: $text-semibold;
	color: map-get($theme-colors, 'ds-main');
	margin-bottom: 4px;
}
select {
	padding: calc(24px / 4) calc(24px / 6);
	height: auto !important;
}

.form-input {
	margin-bottom: 12px;
}

.edit-form {
	input {
		margin-bottom: 12px;
	}
}

.form-input {
	margin-bottom: 12px;
}

.edit-form {
	input {
		margin-bottom: 12px;
	}
}
