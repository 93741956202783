.each-dish-prev {
	@include items-grid(2, $module);
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
	padding: $module;
	background-color: white;
	border-radius: 20px;
	cursor: inherit;
	box-shadow: 0px 0px 5px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.9);
	&:hover {
		box-shadow: 0px 0px 8px 0px transparentize($color: map-get($theme-colors, 'ds-main'), $amount: 0.7);
	}

	.info {
		display: flex;
		flex-direction: column;
		> * {
			margin: 0;
			margin-right: calc(#{$module} / 3);
		}
		h5 {
			margin-bottom: calc(#{$module} / 2);
			color: map-get($theme-colors, 'ds-main');
			font-weight: $text-semibold;
		}
		p {
			@include font-line-height($font-sm);
			strong {
				color: map-get($theme-colors, 'ds-main');
			}
		}
	}
	.btn-group {
		display: flex;
		align-items: center;
		margin-top: calc(#{$module} * 1);
	}

	.alergenos {
		display: flex;
		align-items: center;
		p {
			margin-right: 4px;
		}
		img {
			width: 25px;
			margin-right: 4px;
		}
		.list {
			display: flex;
		}
	}
}
